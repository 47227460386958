import React, { ReactNode, useState, useRef, useMemo, useLayoutEffect, useEffect } from "react";
import { View, StyleSheet, Animated } from "react-native-web";
import Emittery from "emittery";
import { deferred } from "../../utils/deferred";

export function ImperativeModalProvider(p: { children: ReactNode }) {
  return (
    <>
      {p.children}
      <RootModals />
    </>
  );
}

let failsafeTimeout: any;
let modalIdCounter = 1;
let forceUpdateProm = deferred<() => void>();
const forceRerender = () => {
  forceUpdateProm
    .then(fn => {
      clearTimeout(failsafeTimeout);
      fn();
    })
    .catch(console.error);
};

const modals: { contents: ReactNode; modalId: number; uniquenessId?: string }[] = [];

function RootModals() {
  const [_, forceUpdate] = useState(false);
  const isMounted = useRef(true);

  useMemo(() => {
    if (forceUpdateProm.isResolved) {
      forceUpdateProm = deferred<() => void>();
    }

    forceUpdateProm.resolve(() => {
      isMounted.current && forceUpdate(a => !a);
    });
  }, []);

  useLayoutEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {modals.map(({ contents, modalId }, i) => (
        <View key={modalId} style={{ ...StyleSheet.absoluteFillObject, zIndex: i + 50 }} pointerEvents="box-none">
          <NeverUpdate>{contents}</NeverUpdate>
        </View>
      ))}
    </>
  );
}

export function openModal(p: { body: ReactNode; uniquenessId?: string }) {
  let modalId: number;
  const existingModal = p.uniquenessId ? modals.find(m => m.uniquenessId === p.uniquenessId) : false;

  if (existingModal) {
    modalId = existingModal.modalId;
  } else {
    modalId = modalIdCounter++;
    modals.push({
      contents: p.body,
      uniquenessId: p.uniquenessId,
      modalId
    });
    forceRerender();

    if (!forceUpdateProm.isResolved) {
      failsafeTimeout = setTimeout(() => {
        console.error(
          "Unable to open modal without root provider! Ensure the root of your application has a ImperativeModalProvider"
        );
      }, 3000);
    }
  }

  return {
    close: () => {
      const index = modals.findIndex(a => a.modalId === modalId);
      if (index !== -1) {
        modals.splice(index, 1);
        forceRerender();
      }
    }
  };
}

const NeverUpdate = React.memo(
  function NeverUpdate(p: { children: ReactNode }) {
    return <>{p.children}</>;
  },
  () => true
);

/*** Open a modal that slowly fades in and out. Default is 150ms ***/
export function openFadingModal(p: { body: ReactNode; duration?: number; uniquenessId?: string }) {
  const ee = new Emittery();
  const modal = openModal({
    body: (
      <FadingModal ee={ee} duration={p.duration}>
        {p.body}
      </FadingModal>
    ),
    uniquenessId: p.uniquenessId
  });
  return {
    close: async () => {
      ee.emit("requestDestroy").catch(console.error);
      await ee.once("destroyed");
      modal.close();
    }
  };
}

function FadingModal(p: { children: ReactNode; ee: Emittery; duration?: number }) {
  const opacity = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(opacity.current, {
      toValue: 1,
      duration: p.duration ?? 150,
      useNativeDriver: true
    }).start();

    p.ee.on("requestDestroy", () => {
      Animated.timing(opacity.current, {
        toValue: 0,
        duration: p.duration,
        useNativeDriver: true
      }).start(async () => {
        await p.ee.emit("destroyed");
      });
    });
  }, []);

  return (
    <Animated.View pointerEvents="box-none" testID="asdfqwerasdf" style={{ flex: 1, opacity: opacity.current }}>
      {p.children}
    </Animated.View>
  );
}
