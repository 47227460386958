import { CSSProperties, Fragment, ReactNode, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { openModal } from "./imperativeModal";
import { StyledButton } from "../StyledButton";
import clsx from "clsx";

type Props = {
  onClose?: () => void;
  title: string;
  backButtonText?: string;
  bottomButton?: { enabled?: boolean; className?: string; title: string; onPress: () => void | Promise<void> };
  children: ReactNode;
  containerStyle?: CSSProperties;
};

export default function getFullScreenModal(p: Props) {
  const modal = openModal({
    body: (
      <FullScreenModal
        {...p}
        onRequestDismiss={() => {
          modal.close();
        }}
      />
    )
  });

  return modal;
}

export function FullScreenModal(p: Props & { onRequestDismiss: () => void }) {
  const onClose = () => {
    p.onClose?.();
    p.onRequestDismiss();
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={true}
      as="div"
      style={{ zIndex: 50, position: "relative" }}
      onClose={() => {
        onClose();
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity" />

      <div className="fixed inset-0 z-10 flex flex-col justify-center items-center">
        <Dialog.Panel
          style={{ maxHeight: "calc(100% - 30px)", ...(p.containerStyle || {}) }}
          className="max-h-full flex flex-col transform rounded-lg bg-white w-screen sm:max-w-xl box-content"
        >
          <div className="flex text-left bg-gray-100 items-center rounded-t-lg">
            <Dialog.Title as="div" className="m-4 w-full mr-8">
              {p.backButtonText ? (
                <div className="text-blue-500 mt-1 mb-5 flex items-center">
                  <ArrowLeftIcon className="h-3 w-3 mr-2" />
                  <button onClick={onClose}>{p.backButtonText}</button>
                </div>
              ) : null}
              <h1 className="text-2xl font-bold leading-6 text-gray-900 flex-1">{p.title}</h1>
              <button
                type="button"
                className="absolute p-1 top-4 right-0 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-4"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </Dialog.Title>
          </div>
          <div className="flex-1 flex overflow-y-auto">
            <div className="p-4 flex-1">{p.children}</div>
          </div>
          {p.bottomButton ? (
            <div className={clsx("h-20 flex box-content mb-2", p.bottomButton.className)}>
              <div className="flex flex-1 overflow-hidden">
                <div className="flex flex-1 mt-2" style={{ boxShadow: "0 0 10px lightgrey" }}>
                  <StyledButton
                    isLoading={isLoading}
                    disabled={p.bottomButton.enabled === false || isLoading}
                    text={p.bottomButton.title}
                    onClick={async () => {
                      const val = p.bottomButton?.onPress();
                      if (val && (val as any).then) {
                        try {
                          setIsLoading(true);
                          await val;
                        } finally {
                          setIsLoading(false);
                        }
                      }
                    }}
                    className="flex-1 h-12 m-4"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
