import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { LoadingCircle } from "../LoadingCircle";
import { openModal } from "./imperativeModal";
import clsx from "clsx";

type Props = {
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => Promise<void>;
  onError?: () => void;
  title: string;
  subtitle: string;
  confirmButtonColor: "blue" | "red";
};

export default function getConfirm(p: Props): Promise<boolean> {
  return new Promise(res => {
    const modal = openModal({
      body: (
        <ConfirmModal
          {...p}
          onRequestDismiss={value => {
            res(value);
            setTimeout(() => {
              modal.close();
            }, 500); //Keep this in sync with duration...
          }}
        />
      )
    });
  });
}

function ConfirmModal(p: Props & { onRequestDismiss: (val: boolean) => void }) {
  const [isOpen, setIsOpen] = useState(true);
  const isConfirming = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = (val: boolean) => {
    if (isConfirming.current) {
      return;
    }

    p.onClose?.();
    setIsOpen(false);
    p.onRequestDismiss(val);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose(false);
        }}
        style={{ zIndex: 50 }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {p.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {p.subtitle.split(/\n+/).map((substring, index) => {
                        return (
                          <p key={index} className={clsx(`text-sm text-gray-500`, index > 0 && "mt-3")}>
                            {substring}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm${
                      p.confirmButtonColor === "red"
                        ? " hover:bg-red-700 bg-red-600 focus:ring-red-500"
                        : "hover:bg-blue-700 bg-blue-600 focus:ring-blue-500"
                    } `}
                    onClick={async () => {
                      try {
                        isConfirming.current = true;
                        setIsLoading(true);
                        await p.onConfirm?.();
                      } catch (e) {
                        p.onError?.();
                      } finally {
                        setIsLoading(false);
                        isConfirming.current = false;
                        onClose(true);
                      }
                    }}
                  >
                    {isLoading ? <LoadingCircle /> : null}
                    {p.confirmText ?? "Confirm"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      onClose(false);
                    }}
                  >
                    {p.cancelText ?? "Cancel"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
